<!--
 * @Author: dingguowei
 * @Date: 2023-06-27 16:31:34
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-29 16:01:11
-->
<!-- 用户端任务看板 -->
<template>
  <div class="userhome">
    <div class="left">
      <user_home_tasknum></user_home_tasknum>
      <user_home_taskList style="flex: 1"></user_home_taskList>
    </div>
    <div class="right">
      <user_home_remind></user_home_remind>
    </div>
  </div>
</template>

<script>
import user_home_tasknum from "@/components/home/user_home_tasknum.vue";
import user_home_taskList from "@/components/home/user_home_taskList.vue";
import user_home_remind from "@/components/home/user_home_remind.vue";
export default {
  components: {
    user_home_tasknum,
    user_home_taskList,
    user_home_remind,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.userhome {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  .left {
    flex: 5;
    display: flex;
    flex-direction: column;
  }
  .right {
    flex: 2;
    margin-left: 10px;
  }
}
</style>
<!--
 * @Author: dingguowei
 * @Date: 2023-06-29 09:24:02
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 20:05:19
-->
<!-- 用户任务看板任务列表 -->
<template>
  <el-card class="tasklist">
    <div class="title">
      <div class="deco"></div>
      <span>任务列表</span>
    </div>

    <el-table small :fit="true" ref="multipleTable" :data="tableData" :key="tableKey"
      style="width: calc(100% - 14px); font-size: 0.8rem; flex: 1" height="100%">
      <el-table-column label="序号" type="index" :show-overflow-tooltip="true" width="60" align="center">
      </el-table-column>
      <el-table-column label="任务名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="发布者" width="80" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.publisherAccount }}
        </template>
      </el-table-column>
      <el-table-column label="任务状态" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span style="color: blue">
            {{ statusText(scope.row.userStatus) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="接单时间" :show-overflow-tooltip="true" align="center">
        <template slot-scope="scope"> {{ scope.row.createdOn }} </template>
      </el-table-column>

      <el-table-column label="任务结束时间" :show-overflow-tooltip="true" align="center">
        <template slot-scope="scope"> {{ scope.row.endAt }} </template>
      </el-table-column>


    </el-table>

    <el-pagination style="margin-left: auto" small class="pagination" background layout="prev, pager, next" :total="total"
      @current-change="getusermissionpage" :current-page.sync="pageNo" :page-size="pageSize">
    </el-pagination>
  </el-card>
</template>

<script>
import { getusermissionpage } from "@/api/user";
export default {
  components: {},
  data() {
    return {
      pageSize: 10,
      pageNo: 1,
      total: 0,
      tableData: [],
      tableKey: true,
    };
  },
  created() { },
  mounted() {
    this.getusermissionpage();
  },
  watch: {},
  computed: {
    statusText() {
      return function (val) {
        if (val === "TOJUDGE") {
          return "待审核";
        } else if (val === "CLOSED") {
          return "已关闭";
        } else if (val === "FINISHED") {
          return "通过";
        } else if (val === "REFUSED") {
          return "拒绝";
        } else if (val === "FORBID") {
          return "拉黑";
        } else if (val === "TOFINISH") {
          return "进行中";
        }
      };
    },
  },
  methods: {
    // 获取任务列表
    getusermissionpage() {
      getusermissionpage({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
        this.tableKey = !this.tableKey;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.tasklist {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  ::v-deep .el-card {
    height: 100%;
  }

  ::v-deep .el-card__body {
    padding: 10px 0;
  }
}
</style>
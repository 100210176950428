<!--
 * @Author: dingguowei
 * @Date: 2023-06-27 19:32:02
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-30 17:13:08
-->
<!-- 用户任务看板 -->

<template>
  <div class="container">
    <el-card v-for="(item, index) in settingData" class="item" :key="index">
      <div class="text">
        <p>{{ item.name }}</p>
        <p>{{ taskInfo[item.fieId] }}</p>
      </div>
      <el-progress
        type="circle"
        :percentage="percentage(taskInfo.totalNum, taskInfo[item.fieId])"
        :width="70"
        define-back-color="#d1d1d170"
      >
      </el-progress>
    </el-card>
  </div>
</template>

<script>
import { getuserstat } from "@/api/user";

export default {
  components: {},
  data() {
    return {
      settingData: [
        {
          name: "已关闭任务",
          fieId: "closedNum",
          status: "CLOSED",
        },
        {
          name: "进行中任务",
          fieId: "notSubmittedNum",
          status: "TOFINISH",
        },
        {
          name: "审核中任务",
          fieId: "submittedNum",
          status: "TOJUDGE",
        },
        {
          name: "已完成任务",
          fieId: "finishedNum",
          status: "FINISHED",
        },
        {
          name: "被拒绝任务",
          fieId: "refusedNum",
          status: "REFUSED",
        },
        {
          name: "被拉黑任务",
          fieId: "forbidNum",
          status: "FORBID",
        },
      ],
      taskInfo: [],
    };
  },
  created() {},
  mounted() {
    this.initdata();
  },
  watch: {},
  computed: {},
  methods: {
    initdata() {
      getuserstat().then((res) => {
        this.taskInfo = res.data;
      });
    },
    format(e) {
      return () => {
        return e + "";
      };
    },
    percentage(total, per) {
      if (total) {
        return parseFloat(((parseInt(per) / parseInt(total)) * 100).toFixed(0));
      } else {
        return 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* 设置间距 */
  .item {
    --width: calc(calc(100% / 3 - 20px));
    width: var(--width);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 var(--width);
    ::v-deep .el-card__body {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 200%;
      & :first-child {
        font-size: 0.7rem;
        color: #666666;
      }
      & :last-child {
        font-size: 1.5rem;
        color: #0c0c0c;
        font-weight: bold;
      }
    }
  }
}
</style>
<!-- 用户端任务看板任务提醒功能 -->
<template>
  <el-card class="remind">
    <div class="title">
      <div class="deco"></div>
      <span>任务提醒</span>
    </div>
  </el-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.el-card {
  height: 100%;
}
::v-deep .el-card__body {
  padding: 10px 0;
}
</style>